import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Code2, Menu, X, Calendar } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const menuRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Si le menu est ouvert et qu'on clique sur l'overlay
      if (isMenuOpen && overlayRef.current && event.target === overlayRef.current) {
        setIsMenuOpen(false);
      }
    };

    // Ajouter l'écouteur d'événements seulement quand le menu est ouvert
    if (isMenuOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    // Nettoyer l'écouteur d'événements
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  const menuVariants = {
    closed: {
      x: "100%",
    },
    open: {
      x: 0,
    }
  };

  // Fonction pour ajouter une classe si l'onglet est actif
  const isActive = (path: string) => location.pathname === path ? "text-[#FF3366] bg-white/5" : "text-white";

  return (
    <>
      <div className="fixed w-full z-50 px-4 sm:px-6 lg:px-8 top-4">
        <nav className={`max-w-5xl mx-auto rounded-full transition-all duration-300 ${
          scrolled 
            ? 'bg-black/80 backdrop-blur-md shadow-lg shadow-black/20' 
            : 'bg-black/50 backdrop-blur-sm'
        }`}>
          <div className="flex items-center justify-between h-16 px-4 sm:px-8">
            <Link to="/" className="flex-shrink-0">
              <div className="flex items-center gap-2">
                <Code2 className="h-7 w-7 text-[#FF3366]" />
                <span className="text-xl font-medium font-unbounded">izicode</span>
              </div>
            </Link>
            
            {/* Desktop Menu */}
            <div className="hidden lg:block">
              <div className="flex items-center space-x-2">
                <Link 
                  to="/" 
                  className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/")}`}
                >
                  ACCUEIL
                </Link>
                <Link 
                  to="/about" 
                  className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/about")}`}
                >
                  A PROPOS
                </Link>
                <Link 
                  to="/services" 
                  className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/services")}`}
                >
                  SERVICES
                </Link>
                <Link 
                  to="/projects" 
                  className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/projects")}`}
                >
                  PROJETS
                </Link>
                <Link 
                  to="/faq" 
                  className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/faq")}`}
                >
                  FAQs
                </Link>
                <Link 
                  to="/blog" 
                  className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/blog")}`}
                >
                  BLOG
                </Link>
                <Link 
                  to="/contact" 
                  className={`ml-2 px-7 py-2.5 rounded-full text-sm font-medium bg-[#FF3366] text-white hover:bg-[#FF3366]/90 transition-colors ${isActive("/contact")}`}
                >
                  CONTACT
                </Link>
              </div>
            </div>

            {/* Mobile Menu Button */}
            <div className="lg:hidden flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 rounded-lg hover:bg-white/5 transition-colors"
              >
                {isMenuOpen ? (
                  <X className="h-6 w-6 text-white" />
                ) : (
                  <Menu className="h-6 w-6 text-white" />
                )}
              </button>
            </div>
          </div>
        </nav>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <>
            {/* Overlay */}
            <div 
              ref={overlayRef}
              className="fixed inset-0 bg-black/20 backdrop-blur-sm"
              style={{ zIndex: 45 }}
            />

            {/* Menu Content */}
            <div className="fixed inset-0 flex justify-end lg:hidden" style={{ zIndex: 46 }}>
              <motion.div
                ref={menuRef}
                initial="closed"
                animate="open"
                exit="closed"
                variants={menuVariants}
                className="w-full h-full"
              >
                <div className="w-full h-full px-4 pt-24">
                  <div className="max-w-5xl mx-auto">
                    <div className="w-full rounded-3xl border border-white/10 bg-black/90 backdrop-blur-md shadow-xl">
                      <div className="flex flex-col items-center py-8 space-y-4">
                        <Link 
                          to="/" 
                          onClick={() => setIsMenuOpen(false)}
                          className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/")}`}
                        >
                          ACCUEIL
                        </Link>
                        <Link 
                          to="/about"
                          onClick={() => setIsMenuOpen(false)}
                          className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/about")}`}
                        >
                          A PROPOS
                        </Link>
                        <Link 
                          to="/services"
                          onClick={() => setIsMenuOpen(false)}
                          className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/services")}`}
                        >
                          SERVICES
                        </Link>
                        <Link 
                          to="/projects"
                          onClick={() => setIsMenuOpen(false)}
                          className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/projects")}`}
                        >
                          PROJETS
                        </Link>
                        <Link 
                          to="/faq"
                          onClick={() => setIsMenuOpen(false)}
                          className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/faq")}`}
                        >
                          FAQs
                        </Link>
                        <Link 
                          to="/blog"
                          onClick={() => setIsMenuOpen(false)}
                          className={`px-5 py-2.5 rounded-full text-sm font-medium hover:text-[#FF3366] hover:bg-white/5 transition-colors ${isActive("/blog")}`}
                        >
                          BLOG
                        </Link>
                        <Link 
                          to="/contact"
                          onClick={() => setIsMenuOpen(false)}
                          className={`px-7 py-2.5 rounded-full text-sm font-medium bg-[#FF3366] text-white hover:bg-[#FF3366]/90 transition-colors ${isActive("/contact")}`}
                        >
                          CONTACT
                        </Link>
                        {/* Bouton Prendre RDV */}
                        <button
                          className="flex items-center gap-2 px-7 py-2.5 rounded-full text-sm font-medium bg-gradient-to-r from-[#FF3366]/50 to-[#FF6666]/50 text-white cursor-not-allowed"
                          onClick={(e) => e.preventDefault()}
                        >
                          <Calendar className="w-4 h-4" />
                          PRENDRE RDV
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;