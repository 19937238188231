import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react';

interface LoadingState {
  isLoading: boolean;
  progress: number;
  message: string;
  isRealLoading: boolean;
}

interface LoadingContextType {
  loadingState: LoadingState;
  startLoading: (message?: string, isReal?: boolean) => void;
  updateProgress: (progress: number) => void;
  stopLoading: () => void;
  setLoadingMessage: (message: string) => void;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

const MIN_LOADING_TIME = 200; // Temps minimum en ms pour afficher le loader
const FAST_LOADING_THRESHOLD = 100; // Seuil en ms pour considérer un chargement comme rapide

export const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [loadingState, setLoadingState] = useState<LoadingState>({
    isLoading: false,
    progress: 0,
    message: '',
    isRealLoading: false,
  });

  const progressInterval = useRef<NodeJS.Timeout>();
  const loadingStartTime = useRef<number>(0);
  const loadingTimeout = useRef<NodeJS.Timeout>();

  const simulateProgress = useCallback(() => {
    setLoadingState(prev => {
      if (prev.progress >= 90) {
        if (progressInterval.current) {
          clearInterval(progressInterval.current);
        }
        return prev;
      }
      
      // Progression plus rapide si ce n'est pas un vrai chargement
      const increment = prev.isRealLoading ? Math.random() * 8 : Math.random() * 15;
      return {
        ...prev,
        progress: Math.min(prev.progress + increment, 90)
      };
    });
  }, []);

  const startLoading = useCallback((message = 'Chargement...', isReal = false) => {
    loadingStartTime.current = Date.now();
    
    // Si le chargement précédent était trop rapide, ne pas montrer le loader
    const shouldShowLoader = isReal || !loadingStartTime.current || 
      (Date.now() - loadingStartTime.current) > FAST_LOADING_THRESHOLD;

    if (!shouldShowLoader) {
      return;
    }

    setLoadingState({ 
      isLoading: true, 
      progress: 0, 
      message,
      isRealLoading: isReal 
    });
    
    if (progressInterval.current) {
      clearInterval(progressInterval.current);
    }
    
    // Ajuster l'intervalle de progression en fonction du type de chargement
    const intervalTime = isReal ? 300 : 150;
    progressInterval.current = setInterval(simulateProgress, intervalTime);
  }, [simulateProgress]);

  const stopLoading = useCallback(() => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - loadingStartTime.current;
    
    // S'assurer que le loader reste visible pendant au moins MIN_LOADING_TIME
    if (elapsedTime < MIN_LOADING_TIME) {
      loadingTimeout.current = setTimeout(() => {
        setLoadingState(prev => ({ ...prev, isLoading: false, progress: 0 }));
      }, MIN_LOADING_TIME - elapsedTime);
    } else {
      setLoadingState(prev => ({ ...prev, isLoading: false, progress: 0 }));
    }

    if (progressInterval.current) {
      clearInterval(progressInterval.current);
    }
  }, []);

  const updateProgress = useCallback((progress: number) => {
    setLoadingState(prev => ({ ...prev, progress }));
  }, []);

  const setLoadingMessage = useCallback((message: string) => {
    setLoadingState(prev => ({ ...prev, message }));
  }, []);

  useEffect(() => {
    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
    };
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        loadingState,
        startLoading,
        updateProgress,
        stopLoading,
        setLoadingMessage,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
