import Cookies from 'js-cookie';

export type CookiePreferences = {
  essential: boolean;
  analytics: boolean;
  preferences: boolean;
};

class CookieService {
  private readonly COOKIE_PREFERENCES_KEY = 'cookieConsent';
  private readonly COOKIE_EXPIRY_DAYS = 365;

  // Initialiser les services en fonction des préférences
  initializeServices(preferences: string[]) {
    if (preferences.includes('analytics')) {
      this.initializeAnalytics();
    }
    if (preferences.includes('preferences')) {
      this.initializePreferencesCookies();
    }
    // Les cookies essentiels sont toujours initialisés
    this.initializeEssentialCookies();
  }

  // Sauvegarder les préférences
  savePreferences(preferences: string[]) {
    try {
      // Filtrer pour ne garder que les préférences valides
      const validPreferences = preferences.filter(pref => 
        ['essential', 'analytics', 'preferences'].includes(pref)
      );
      
      // S'assurer que 'essential' est toujours inclus
      if (!validPreferences.includes('essential')) {
        validPreferences.push('essential');
      }

      // Sauvegarder dans localStorage pour l'état du popup
      localStorage.setItem(this.COOKIE_PREFERENCES_KEY, JSON.stringify(validPreferences));
      
      // Convertir en objet de préférences
      const preferencesObj: CookiePreferences = {
        essential: true, // Toujours true
        analytics: validPreferences.includes('analytics'),
        preferences: validPreferences.includes('preferences'),
      };

      // Sauvegarder les préférences dans un cookie
      Cookies.set('cookiePreferences', JSON.stringify(preferencesObj), {
        expires: this.COOKIE_EXPIRY_DAYS,
        sameSite: 'strict'
      });

      // Initialiser les services selon les préférences
      this.initializeServices(validPreferences);

      // Si certains cookies ne sont pas acceptés, les supprimer
      if (!preferencesObj.analytics) {
        this.removeAnalyticsCookies();
      }
      if (!preferencesObj.preferences) {
        this.removePreferencesCookies();
      }
    } catch (error) {
      console.error('Error saving cookie preferences:', error);
    }
  }

  // Récupérer les préférences
  getPreferences(): string[] | null {
    try {
      const preferences = localStorage.getItem(this.COOKIE_PREFERENCES_KEY);
      if (!preferences) return null;
      
      const parsedPreferences = JSON.parse(preferences);
      if (!Array.isArray(parsedPreferences)) return null;
      
      // Vérifier que les préférences sont valides
      const validPreferences = parsedPreferences.filter(pref => 
        ['essential', 'analytics', 'preferences'].includes(pref)
      );
      
      return validPreferences.length > 0 ? validPreferences : null;
    } catch {
      return null;
    }
  }

  // Initialiser Google Analytics
  private initializeAnalytics() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': 'granted'
      });
    }
  }

  // Initialiser les cookies de préférences
  private initializePreferencesCookies() {
    // Implémenter la logique pour les cookies de préférences
    // Par exemple : thème, langue, etc.
  }

  // Initialiser les cookies essentiels
  private initializeEssentialCookies() {
    // Implémenter la logique pour les cookies essentiels
    // Par exemple : session, CSRF token, etc.
  }

  // Supprimer les cookies analytiques
  private removeAnalyticsCookies() {
    // Supprimer les cookies de Google Analytics
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const cookieName = cookie.split('=')[0].trim();
      if (cookieName.startsWith('_ga') || cookieName.startsWith('_gid')) {
        Cookies.remove(cookieName, { path: '/' });
      }
    }
    
    // Désactiver la collecte Google Analytics
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': 'denied'
      });
    }
  }

  // Supprimer les cookies de préférences
  private removePreferencesCookies() {
    // Implémenter la logique pour supprimer les cookies de préférences
  }
}

export const cookieService = new CookieService();
