import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { X, Cookie, ExternalLink } from 'lucide-react'
import { cookieService } from '../services/cookieService'

type CookieType = {
  id: string;
  name: string;
  description: string;
  required: boolean;
}

const cookieTypes: CookieType[] = [
  {
    id: 'essential',
    name: 'Cookies essentiels',
    description: 'Nécessaires au fonctionnement du site',
    required: true
  },
  {
    id: 'analytics',
    name: 'Cookies analytiques',
    description: 'Nous aident à améliorer votre expérience',
    required: false
  },
  {
    id: 'preferences',
    name: 'Cookies de préférences',
    description: 'Sauvegardent vos choix et personnalisations',
    required: false
  }
]

export default function CookieConsent() {
  const [showConsent, setShowConsent] = useState(false)
  const [selectedCookies, setSelectedCookies] = useState<string[]>(['essential'])
  const [hasResponded, setHasResponded] = useState(false)

  useEffect(() => {
    try {
      const savedCookies = cookieService.getPreferences()
      if (savedCookies && Array.isArray(savedCookies)) {
        setSelectedCookies(savedCookies)
        // Si on a des préférences sauvegardées, on initialise les services
        cookieService.initializeServices(savedCookies)
        setShowConsent(false) // On cache le popup
      } else {
        // Si pas de préférences, on affiche le popup après 5 secondes
        const timer = setTimeout(() => {
          setShowConsent(true)
        }, 5000)
        return () => clearTimeout(timer)
      }
    } catch (error) {
      console.error('Error loading cookie preferences:', error)
      setShowConsent(true)
    }
  }, [])

  const toggleCookie = (cookieId: string) => {
    if (cookieId === 'essential') return
    setSelectedCookies(prev => 
      prev.includes(cookieId) 
        ? prev.filter(id => id !== cookieId)
        : [...prev, cookieId]
    )
  }

  const handleAccept = () => {
    try {
      cookieService.savePreferences(selectedCookies)
      setHasResponded(true)
      setShowConsent(false)
    } catch (error) {
      console.error('Error saving cookie preferences:', error)
    }
  }

  const handleDecline = () => {
    try {
      cookieService.savePreferences(['essential'])
      setHasResponded(true)
      setShowConsent(false)
    } catch (error) {
      console.error('Error saving cookie preferences:', error)
    }
  }

  return (
    <AnimatePresence>
      {showConsent && (
        <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="relative w-full max-w-lg mx-4 bg-[#111826] rounded-2xl shadow-xl"
          >
            <button
              onClick={handleDecline}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-300 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>

            <div className="p-6">
              <div className="flex items-center gap-4 mb-6">
                <div className="bg-[#FF3366]/10 p-3 rounded-xl">
                  <Cookie className="w-8 h-8 text-[#FF3366]" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-white">
                    Gérer vos préférences
                  </h3>
                  <p className="text-gray-400 mt-1">
                    Nous utilisons des cookies pour améliorer votre expérience sur notre site. Vous pouvez choisir les cookies que vous acceptez.
                  </p>
                </div>
              </div>

              <div className="space-y-4">
                {cookieTypes.map(cookie => (
                  <div 
                    key={cookie.id}
                    className="flex items-start gap-3"
                  >
                    <div className="flex items-center h-6 pt-0.5">
                      <input
                        type="checkbox"
                        checked={selectedCookies.includes(cookie.id)}
                        onChange={() => toggleCookie(cookie.id)}
                        disabled={cookie.required}
                        className="w-4 h-4 text-[#FF3366] bg-gray-700 border-gray-600 rounded focus:ring-[#FF3366] dark:focus:ring-[#FF3366] dark:ring-offset-gray-800 focus:ring-2"
                      />
                    </div>
                    <div>
                      <label className="font-medium text-white flex items-center">
                        {cookie.name}
                        {cookie.required && (
                          <span className="ml-2 text-sm text-gray-400">(Requis)</span>
                        )}
                      </label>
                      <p className="text-gray-400 text-sm">
                        {cookie.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-8 space-y-3">
                <button
                  onClick={handleAccept}
                  className="w-full bg-[#FF3366] text-white py-2.5 rounded-xl text-sm font-medium hover:bg-[#FF3366]/90 transition-colors"
                >
                  Accepter les cookies sélectionnés
                </button>
                <button
                  onClick={handleDecline}
                  className="w-full py-2.5 text-sm font-medium text-gray-400 hover:text-gray-300 transition-colors"
                >
                  Continuer sans accepter
                </button>
                <div className="text-center">
                  <a 
                    href="/politique-cookies"
                    className="inline-flex items-center gap-1 text-sm text-gray-400 hover:text-[#FF3366] transition-colors"
                  >
                    Politique de cookies
                    <ExternalLink className="w-4 h-4" />
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
