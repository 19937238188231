import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from './context/ThemeContext';
import { LoadingProvider, useLoading } from './context/LoadingContext';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import ChatBubble from './components/ChatBubble';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import './styles/scrollbar-hide.css';
import CookieConsent from './components/CookieConsent';
import { componentLoader } from './services/componentLoader';
import { preloadService } from './services/preloadService';

// Lazy load components using componentLoader
const Home = componentLoader.lazyLoad('Home');
const About = componentLoader.lazyLoad('About');
const Services = componentLoader.lazyLoad('Services');
const Projects = componentLoader.lazyLoad('Projects');
const Blog = componentLoader.lazyLoad('Blog');
const BlogPost = componentLoader.lazyLoad('BlogPost');
const Contact = componentLoader.lazyLoad('Contact');
const FAQ = componentLoader.lazyLoad('FAQ');
const MentionsLegales = componentLoader.lazyLoad('MentionsLegales');
const PolitiqueConfidentialite = componentLoader.lazyLoad('PolitiqueConfidentialite');
const CGU = componentLoader.lazyLoad('CGU');
const PolitiqueCookies = componentLoader.lazyLoad('PolitiqueCookies');
const Quote = componentLoader.lazyLoad('Quote');
const QuoteIA = componentLoader.lazyLoad('QuoteIA');
const ErrorPage = componentLoader.lazyLoad('ErrorPage');
const Unsubscribe = componentLoader.lazyLoad('Unsubscribe');
const ConfirmNewsletter = componentLoader.lazyLoad('ConfirmNewsletter');
const DevisChat = componentLoader.lazyLoad('DevisChat');

// Loading component avec progression améliorée
const PageLoader = () => {
  const { loadingState } = useLoading();
  
  if (!loadingState.isLoading) return null;

  return (
    <div className="fixed inset-0 bg-black/90 flex flex-col items-center justify-center z-50">
      <div className="relative">
        <div className="w-16 h-16 border-4 border-[#FF3366] border-t-transparent rounded-full animate-spin"></div>
        <div className="w-16 h-16 border-4 border-white/10 rounded-full absolute top-0"></div>
      </div>
      <div className="mt-4 text-center">
        <p className="text-slate-400">{loadingState.message}</p>
        {loadingState.isRealLoading && (
          <div className="w-48 h-1 bg-white/10 rounded-full mt-2 overflow-hidden">
            <div 
              className="h-full bg-[#FF3366] transition-all duration-300 ease-out"
              style={{ width: `${loadingState.progress}%` }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// Router component with preloading
const AppRouter = () => {
  const location = useLocation();

  useEffect(() => {
    // Précharger les composants lors du changement de route
    componentLoader.preloadComponents(location.pathname);
  }, [location]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
        <Route path="/cgu" element={<CGU />} />
        <Route path="/politique-cookies" element={<PolitiqueCookies />} />
        <Route path="/devis" element={<Quote />} />
        <Route path="/devis-ia" element={<QuoteIA />} />
        <Route path="/devis-chat" element={<DevisChat />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/confirm-newsletter" element={<ConfirmNewsletter />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AnimatePresence>
  );
};

const App: React.FC = () => {
  useEffect(() => {
    // Initialize preloading
    preloadService.initializePreloading();
    // Start preloading high priority components
    componentLoader.preloadComponents('/');
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <LoadingProvider>
          <Router>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Izicode - Développement web sur mesure</title>
              <meta name="description" content="Izicode - Agence de développement web sur mesure. Création de sites web, applications web et mobiles." />
            </Helmet>
            <div className="flex flex-col min-h-screen bg-white dark:bg-[#0A0A0A]">
              <Navbar />
              <React.Suspense fallback={<PageLoader />}>
                <AppRouter />
              </React.Suspense>
              <ChatBubble />
              <Footer />
              <ScrollToTop />
            </div>
            <Toaster position="bottom-right" />
            <CookieConsent />
          </Router>
        </LoadingProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;