import { useEffect, useState, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { X, MessageCircle } from 'lucide-react'

interface ChatPopupProps {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
}

export default function ChatPopup({ isModalOpen, setIsModalOpen }: ChatPopupProps) {
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    // Vérifie si le popup a déjà été montré
    const hasBeenShown = localStorage.getItem('chatPopupShown')
    
    // Si le chat n'est pas ouvert ET que le popup n'a jamais été montré
    if (!isModalOpen && !hasBeenShown) {
      const timer = setTimeout(() => {
        setShowPopup(true)
        // Marquer le popup comme montré
        localStorage.setItem('chatPopupShown', 'true')
      }, 120000) // 2 minutes

      return () => clearTimeout(timer)
    }
  }, [isModalOpen])

  const handleClose = useCallback(() => {
    setShowPopup(false)
  }, [])

  const handleChatOpen = () => {
    setShowPopup(false)
    setIsModalOpen(true)
    localStorage.setItem('chatOpened', 'true')
  }

  // Gestionnaire pour fermer le popup quand on clique à l'extérieur
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (showPopup && !target.closest('[data-popup]')) {
        handleClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [showPopup, handleClose])

  return (
    <AnimatePresence>
      {showPopup && !isModalOpen && (
        <motion.div
          data-popup
          initial={{ opacity: 0, y: 20, scale: 0.8 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: 20, scale: 0.8 }}
          className="fixed bottom-24 right-8 z-50"
        >
          <div className="bg-white dark:bg-[#111826] backdrop-blur-xl rounded-2xl shadow-lg shadow-black/10 border border-white/10 p-6 max-w-sm">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
            >
              <X className="w-5 h-5" />
            </button>
            
            <div className="flex items-start gap-4">
              <div className="bg-[#FF3366]/10 p-3 rounded-xl">
                <MessageCircle className="w-6 h-6 text-[#FF3366]" />
              </div>
              <div>
                <h3 className="font-semibold text-lg mb-2 text-black dark:text-white">
                  Besoin d'aide ?
                </h3>
                <p className="text-gray-600 dark:text-gray-400 text-sm mb-4">
                  Notre assistant est là pour répondre à toutes vos questions. Commencez une discussion maintenant&nbsp;!
                </p>
                <button
                  onClick={handleChatOpen}
                  className="bg-[#FF3366] text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-[#FF3366]/90 transition-colors flex items-center gap-2"
                >
                  Démarrer la discussion
                  <MessageCircle className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
