type StorageType = 'local' | 'session';

interface StorageItem {
  value: any;
  timestamp: number;
  expiry?: number;
}

class StorageService {
  private readonly prefix: string = 'izicode_';
  private readonly defaultExpiry: number = 7 * 24 * 60 * 60 * 1000; // 7 days

  private getStorage(type: StorageType): Storage {
    return type === 'local' ? localStorage : sessionStorage;
  }

  private getKey(key: string): string {
    return `${this.prefix}${key}`;
  }

  set(key: string, value: any, options: { 
    type?: StorageType;
    expiry?: number;
  } = {}): void {
    try {
      const storage = this.getStorage(options.type || 'local');
      const item: StorageItem = {
        value,
        timestamp: Date.now(),
        expiry: options.expiry || this.defaultExpiry
      };
      storage.setItem(this.getKey(key), JSON.stringify(item));
    } catch (error) {
      console.error('Storage set error:', error);
    }
  }

  get<T>(key: string, type: StorageType = 'local'): T | null {
    try {
      const storage = this.getStorage(type);
      const item = storage.getItem(this.getKey(key));
      
      if (!item) return null;

      const { value, timestamp, expiry }: StorageItem = JSON.parse(item);
      
      if (expiry && Date.now() - timestamp > expiry) {
        this.remove(key, type);
        return null;
      }

      return value as T;
    } catch (error) {
      console.error('Storage get error:', error);
      return null;
    }
  }

  remove(key: string, type: StorageType = 'local'): void {
    try {
      const storage = this.getStorage(type);
      storage.removeItem(this.getKey(key));
    } catch (error) {
      console.error('Storage remove error:', error);
    }
  }

  clear(type?: StorageType): void {
    try {
      if (type) {
        const storage = this.getStorage(type);
        Object.keys(storage)
          .filter(key => key.startsWith(this.prefix))
          .forEach(key => storage.removeItem(key));
      } else {
        // Clear both storages
        ['local', 'session'].forEach(t => this.clear(t as StorageType));
      }
    } catch (error) {
      console.error('Storage clear error:', error);
    }
  }

  // Utility method to check if storage is available
  isAvailable(type: StorageType = 'local'): boolean {
    try {
      const storage = this.getStorage(type);
      const testKey = `${this.prefix}test`;
      storage.setItem(testKey, 'test');
      storage.removeItem(testKey);
      return true;
    } catch {
      return false;
    }
  }
}

export const storageService = new StorageService();
