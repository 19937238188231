import React, { useState, useRef, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle, X, Send, Loader2, Calendar, Phone, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import ChatPopup from './ChatPopup';

const ChatBubble = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([
    {
      sender: 'Izicode',
      content: '👋 Bonjour ! Comment puis-je vous aider aujourd’hui ?',
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    },
  ]);
  const [isSending, setIsSending] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const lastScrollY = useRef(0);
  const chatRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (isOpen && chatRef.current && !chatRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  // Référence pour scroller automatiquement
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      if (currentScrollY > lastScrollY.current) {
        // Scrolling down
        setShowButton(false);
      } else {
        // Scrolling up
        setShowButton(true);
      }
      
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const synonyms = {
    salutations: ['bonjour', 'salut', 'hello', 'hey', 'coucou', 'bonsoir'],
    horaires: ['horaires', 'heures', 'ouvert', 'quand', 'fermé', 'horaire'],
    téléphone: ['téléphone', 'tel', 'numero', 'numéro', 'contacter', 'appel'],
    mail: ['email', 'mail', 'courriel', 'adresse mail', 'contact email'],
    services: ['services', 'proposez', 'faire', 'offres', 'aidez', 'offrez'],
    merci: ['merci', 'thanks', 'thx', 'cool', 'super', 'parfait'],
    adresse: ['adresse', 'où', 'localisation', 'situé', 'bureaux'],
  };

  const predefinedResponses = {
    salutations: '👋 Bonjour ! Que puis-je faire pour vous aujourd’hui ? 😊',
    horaires: '🕘 Nous sommes ouverts du lundi au vendredi, de 9h à 17h, et le samedi de 9h à 12h sur rendez-vous.',
    téléphone: '📞 Vous pouvez nous joindre au +33 6 83 91 97 38. Nous serons ravis de répondre à vos questions !',
    mail: '📧 Vous pouvez nous écrire à contact@izicode.fr. Nous répondons rapidement !',
    services: '💻 Nous offrons des services de développement web, mobile, de design UX/UI, de SEO, et bien plus encore. Dites-moi ce dont vous avez besoin !',
    merci: '😊 Avec plaisir ! Si vous avez d’autres questions, je suis là pour vous aider !',
    adresse: '📍 Nous sommes situés à Amiens, France. Vous êtes toujours le bienvenu pour discuter de vos projets.',
  };

  const detectIntent = (input: string) => {
    input = input.toLowerCase();
    for (const [key, variations] of Object.entries(synonyms)) {
      if (variations.some((v) => input.includes(v))) {
        return key;
      }
    }
    return null;
  };

  const getResponse = (input: string) => {
    const intent = detectIntent(input);
    if (intent && predefinedResponses[intent]) {
      return { content: predefinedResponses[intent], intent };
    }
    return { content: "🤔 Je n'ai pas compris votre question. Pouvez-vous reformuler ? 😊", intent: null };
  };

  const getActionButton = (intent: string | null, content: string) => {
    if (intent === 'téléphone') {
      const phone = content.match(/\+[\d\s]+/)?.[0].replace(/\s/g, '') || '';
      return (
        <a
          href={`tel:${phone}`}
          className="inline-flex items-center gap-2 mt-2 px-4 py-2 bg-gradient-to-r from-[#2C3E50] to-[#34495E] hover:from-[#243342] hover:to-[#2C3E50] text-white rounded-lg text-sm transition-all duration-300"
        >
          <Phone className="w-4 h-4" />
          Appeler
        </a>
      );
    }
    if (intent === 'mail') {
      const email = content.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/i)?.[0] || '';
      return (
        <a
          href={`mailto:${email}`}
          className="inline-flex items-center gap-2 mt-2 px-4 py-2 bg-gradient-to-r from-[#2C3E50] to-[#34495E] hover:from-[#243342] hover:to-[#2C3E50] text-white rounded-lg text-sm transition-all duration-300"
        >
          <Mail className="w-4 h-4" />
          Envoyer un email
        </a>
      );
    }
    return null;
  };

  const makeContentClickable = (content: string) => {
    const emailRegex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const phoneRegex = /\b(?:\+?\d{1,3})?[ -]?\(?\d{1,4}\)?[ -]?\d{1,4}[ -]?\d{1,4}[ -]?\d{1,4}\b/g;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return content.split(' ').map((word, index) => {
      if (emailRegex.test(word)) {
        return (
          <a key={index} href={`mailto:${word}`} className="text-[#FF3366] underline">
            {word}
          </a>
        );
      }
      if (phoneRegex.test(word)) {
        const cleanPhone = word.replace(/[^+\d]/g, '');
        return (
          <a key={index} href={`tel:${cleanPhone}`} className="text-[#FF3366] underline">
            {word}
          </a>
        );
      }
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer" className="text-[#FF3366] underline">
            {word}
          </a>
        );
      }
      return word + ' ';
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim()) return;

    const userMessage = {
      sender: 'Vous',
      content: message,
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };

    setMessages([...messages, userMessage]);
    setMessage('');
    setIsSending(true);

    // Maintenir le focus sur l'input après l'envoi
    if (inputRef.current) {
      inputRef.current.focus();
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    const response = getResponse(userMessage.content);
    const botResponse = {
      sender: 'Izicode',
      content: response.content,
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      intent: response.intent,
    };

    setMessages((prevMessages) => [...prevMessages, botResponse]);
    setIsSending(false);

    // Maintenir le focus après la réponse du bot
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
    // Enregistre que l'utilisateur a ouvert le chat
    localStorage.setItem('chatOpened', 'true');
  };

  return (
    <>
      <ChatPopup isModalOpen={isOpen} setIsModalOpen={setIsOpen} />
      
      <div className="fixed bottom-4 right-4 z-50 flex items-center gap-4">
        {/* Bouton Prendre RDV */}
        <AnimatePresence>
          {showButton && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.2 }}
              className="relative flex flex-col items-center hidden md:flex"
            >
              <button
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className="flex items-center gap-2 bg-gradient-to-r from-[#FF3366]/50 to-[#FF6666]/50 text-white px-4 py-2 rounded-full cursor-not-allowed"
              >
                <Calendar className="w-5 h-5" />
                <span>Prendre RDV</span>
              </button>

              {/* Infobulle "Bientôt disponible" */}
              <AnimatePresence>
                {showTooltip && (
                  <motion.div
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    className="absolute -top-10 text-center px-3 py-1 bg-black/80 text-white text-sm rounded"
                  >
                    Bientôt disponible
                    <div className="absolute left-1/2 -translate-x-1/2 -bottom-1 w-2 h-2 rotate-45 bg-black/80"></div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Bouton pour ouvrir le chat */}
        <motion.button
          onClick={handleOpen}
          className="bg-gradient-to-r from-[#FF3366] to-[#FF6666] text-white p-4 rounded-full shadow-lg hover:opacity-90 transition-opacity"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {isOpen ? <X className="w-6 h-6" /> : <MessageCircle className="w-6 h-6" />}
        </motion.button>

        {/* Chat Window */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              ref={chatRef}
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 20, scale: 0.95 }}
              transition={{ duration: 0.2 }}
              className="fixed sm:absolute bottom-0 sm:bottom-20 right-0 w-full sm:w-96 h-[85vh] sm:h-auto bg-white dark:bg-[#111826] sm:rounded-2xl shadow-xl border-t sm:border border-gray-200 dark:border-white/10 overflow-hidden"
            >
              <div className="p-4 bg-[#FF3366] text-white">
                <div className="flex items-center justify-between">
                  <h3 className="font-bold">Chat en direct</h3>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="p-1 hover:bg-white/20 rounded-full transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
                <p className="text-sm opacity-90">Posez vos questions, je suis là pour vous aider !</p>
              </div>

              <div className="h-[calc(85vh-180px)] sm:h-80 overflow-y-auto p-4 space-y-4">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`flex items-start gap-2.5 ${msg.sender === 'Vous' ? 'justify-end' : ''}`}
                  >
                    {msg.sender !== 'Vous' && (
                      <div className="w-8 h-8 rounded-full bg-[#FF3366] flex items-center justify-center text-white font-bold">
                        I
                      </div>
                    )}
                    <div
                      className={`flex flex-col gap-1 ${
                        msg.sender === 'Vous' ? 'items-end' : 'items-start'
                      }`}
                    >
                      <div className="flex items-center gap-2">
                        <span className="font-medium">{msg.sender}</span>
                        <span className="text-xs text-gray-500 dark:text-gray-400">{msg.time}</span>
                      </div>
                      <div
                        className={`rounded-lg p-3 text-sm ${
                          msg.sender === 'Vous'
                            ? 'bg-[#FF3366] text-white'
                            : 'bg-gray-100 dark:bg-gray-800 text-black dark:text-white'
                        }`}
                      >
                        {makeContentClickable(msg.content)}
                        {msg.sender === 'Izicode' && msg.intent && (
                          <div className="mt-1">
                            {getActionButton(msg.intent, msg.content)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>

              <form onSubmit={handleSubmit} className="p-4 border-t dark:border-gray-800">
                <div className="flex gap-2">
                  <input
                    ref={inputRef}
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Écrivez votre message..."
                    className="flex-1 p-2 rounded-lg bg-gray-100 dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-[#FF3366]"
                    autoComplete="off"
                    onFocus={(e) => {
                      // Empêcher le scroll automatique sur focus
                      e.preventDefault();
                      e.target.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    }}
                  />
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    disabled={isSending}
                    className="p-2 bg-[#FF3366] text-white rounded-lg disabled:opacity-50"
                  >
                    {isSending ? (
                      <Loader2 className="w-5 h-5 animate-spin" />
                    ) : (
                      <Send className="w-5 h-5" />
                    )}
                  </motion.button>
                </div>
              </form>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default ChatBubble;