import { lazy } from 'react';
import { storageService } from './storageService';

// Type definitions
type ComponentImport = () => Promise<{ default: React.ComponentType<any> }>;
type RouteConfig = {
  path: string;
  component: string;
  priority: 'high' | 'medium' | 'low';
};

class ComponentLoader {
  private loadedComponents: Set<string> = new Set();
  private isPreloading: boolean = false;
  private loadingComponents: Set<string> = new Set();

  // Configuration des routes et leur priorité
  private routes: RouteConfig[] = [
    { path: '/', component: 'Home', priority: 'high' },
    { path: '/about', component: 'About', priority: 'high' },
    { path: '/services', component: 'Services', priority: 'high' },
    { path: '/projects', component: 'Projects', priority: 'medium' },
    { path: '/blog', component: 'Blog', priority: 'medium' },
    { path: '/contact', component: 'Contact', priority: 'medium' },
    { path: '/faq', component: 'FAQ', priority: 'low' },
    { path: '/quote', component: 'Quote', priority: 'high' },
    { path: '/quote-ia', component: 'QuoteIA', priority: 'high' },
    { path: '/devis-chat', component: 'DevisChat', priority: 'high' },
  ];

  // Lazy load avec retry et tracking
  public lazyLoad = (componentPath: string, maxRetries: number = 3): React.LazyExoticComponent<React.ComponentType<any>> => {
    const importComponent = async () => {
      let lastError;
      
      for (let i = 0; i < maxRetries; i++) {
        try {
          this.loadingComponents.add(componentPath);
          const component = await import(`../pages/${componentPath}.tsx`);
          this.loadedComponents.add(componentPath);
          this.loadingComponents.delete(componentPath);
          return component;
        } catch (error) {
          lastError = error;
          await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, i))); // Exponential backoff
        }
      }
      
      throw lastError;
    };

    return lazy(importComponent);
  };

  public isLoading(componentName: string): boolean {
    return this.loadingComponents.has(componentName);
  }

  // Préchargement intelligent des composants
  public preloadComponents = async (currentPath: string): Promise<void> => {
    if (this.isPreloading || storageService.get('componentsPreloaded', 'session')) return;

    this.isPreloading = true;
    
    try {
      // Précharger d'abord les composants haute priorité
      const highPriorityRoutes = this.routes.filter(route => 
        route.priority === 'high' && route.path !== currentPath
      );

      await Promise.all(
        highPriorityRoutes.map(route => 
          import(`../pages/${route.component}.tsx`)
            .catch(error => console.error(`Failed to preload ${route.component}:`, error))
        )
      );

      // Précharger les composants de priorité moyenne en arrière-plan
      setTimeout(() => {
        this.routes
          .filter(route => route.priority === 'medium')
          .forEach(route => {
            import(`../pages/${route.component}.tsx`)
              .catch(error => console.error(`Failed to preload ${route.component}:`, error));
          });
      }, 3000);

      storageService.set('componentsPreloaded', true, { type: 'session' });
    } catch (error) {
      console.error('Error during preloading:', error);
    } finally {
      this.isPreloading = false;
    }
  };

  // Précharger un composant spécifique
  public preloadComponent = async (componentPath: string): Promise<void> => {
    if (this.loadedComponents.has(componentPath)) return;

    try {
      await import(`../pages/${componentPath}.tsx`);
      this.loadedComponents.add(componentPath);
    } catch (error) {
      console.error(`Failed to preload ${componentPath}:`, error);
    }
  };

  // Vérifier si un composant est déjà chargé
  public isComponentLoaded = (componentPath: string): boolean => {
    return this.loadedComponents.has(componentPath);
  };
}

export const componentLoader = new ComponentLoader();
