import React from 'react';
import { Link } from 'react-router-dom';
import { Code2, Facebook, Instagram, Linkedin, Phone } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-black/50 pt-12 sm:pt-16 lg:pt-20 pb-8 sm:pb-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-12 mb-12 sm:mb-16">
          <div>
            <div className="flex items-center gap-2 mb-4 sm:mb-6">
              <Code2 className="h-6 w-6 sm:h-8 sm:w-8 text-[#FF3366]" />
              <span className="text-lg sm:text-xl font-bold">IZICODE</span>
            </div>
            <p className="text-gray-400 text-sm sm:text-base">
              Votre partenaire digital pour des solutions web innovantes et performantes.
            </p>
          </div>
          
          <div className="flex flex-col space-y-4">
            <h3 className="text-lg font-semibold text-[#FF3366]">Mentions Légales</h3>
            <Link to="/mentions-legales" className="hover:text-[#FF3366] transition-colors">Mentions Légales</Link>
            <Link to="/politique-confidentialite" className="hover:text-[#FF3366] transition-colors">Politique de Confidentialité</Link>
            <Link to="/cgu" className="hover:text-[#FF3366] transition-colors">CGU</Link>
            <Link to="/politique-cookies" className="hover:text-[#FF3366] transition-colors">Politique de Cookies</Link>
          </div>
          
          <div>
            <h3 className="font-bold text-base sm:text-lg mb-4 sm:mb-6">Liens Utiles</h3>
            <ul className="space-y-2 sm:space-y-4 text-gray-400 text-sm sm:text-base">
              <li>
                <Link to="/about" className="hover:text-[#FF3366] transition-colors">
                  À propos
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:text-[#FF3366] transition-colors">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/blog" className="hover:text-[#FF3366] transition-colors">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-[#FF3366] transition-colors">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="font-bold text-base sm:text-lg mb-4 sm:mb-6">Suivez-nous</h3>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/izicode.fr/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-[#FF3366] transition-colors">
                <Facebook className="w-5 h-5 sm:w-6 sm:h-6" />
              </a>
              <a href="https://wa.me/33610354259" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-[#FF3366] transition-colors">
                <Phone className="w-5 h-5 sm:w-6 sm:h-6" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-[#FF3366] transition-colors">
                <Instagram className="w-5 h-5 sm:w-6 sm:h-6" />
              </a>
              <a href="https://www.linkedin.com/company/izicode-france/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-[#FF3366] transition-colors">
                <Linkedin className="w-5 h-5 sm:w-6 sm:h-6" />
              </a>
            </div>
          </div>
        </div>
        
        <div className="border-t border-gray-800 pt-6 sm:pt-8">
          <p className="text-center text-gray-400 text-sm sm:text-base">
            {new Date().getFullYear()} Izicode. Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;