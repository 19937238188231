import { storageService } from './storageService';

class PreloadService {
  private readonly routes: string[] = [
    '/',
    '/about',
    '/services',
    '/projects',
    '/blog',
    '/contact'
  ];

  private readonly resources: string[] = [
    '/manifest.json',
    '/favicon.ico'
  ];

  async preloadRoute(route: string): Promise<void> {
    try {
      const link = document.createElement('link');
      link.rel = 'prefetch';
      link.href = route;
      document.head.appendChild(link);
    } catch (error) {
      console.error(`Error preloading route ${route}:`, error);
    }
  }

  async preloadImage(url: string): Promise<void> {
    try {
      const img = new Image();
      img.src = url;
    } catch (error) {
      console.error(`Error preloading image ${url}:`, error);
    }
  }

  async preloadCommonRoutes(): Promise<void> {
    for (const route of this.routes) {
      await this.preloadRoute(route);
    }
  }

  async preloadResources(): Promise<void> {
    for (const resource of this.resources) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = resource;
      link.as = resource.endsWith('.json') ? 'fetch' : 'image';
      document.head.appendChild(link);
    }
  }

  async initializePreloading(): Promise<void> {
    // Only preload if we haven't done so in this session
    if (!storageService.get('preloaded', 'session')) {
      await Promise.all([
        this.preloadCommonRoutes(),
        this.preloadResources()
      ]);
      storageService.set('preloaded', true, { type: 'session' });
    }
  }
}

export const preloadService = new PreloadService();
